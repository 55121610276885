<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <strong style="opacity:0;">.</strong>
                    <v-autocomplete
                      v-model="model.proveedor_id"
                      class="pt-2"
                      :items="itemsProveedor"
                      :search-input.sync="searchProveedor"
                      hide-details
                      hide-selected
                      :label="$t('lbl.proveedor')"
                      outlined
                      dense
                      item-text="name_comercial"
                      item-value="id"
                      @change="changeProveedor"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.proveedor') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <span v-text="item.name_comercial"></span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <strong style="opacity:0;">.</strong>
                    <v-select
                      v-model="model.source"
                      :items="sources"
                      label="Fuente"
                      outlined
                      dense
                      item-text="name"
                      item-value="slug"
                      multiple
                      class="pt-2"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <strong style="opacity:0;">.</strong>
                    <v-text-field
                      v-model="model.identificador"
                      :label="$t('lbl.identificador')"
                      class="pt-2"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <strong style="opacity:0;">.</strong>
                    <v-select
                      v-model="model.currency_id"
                      :items="currencies"
                      :label="$t('register.moneda')"
                      outlined
                      dense
                      item-text="code"
                      item-value="id"
                      class="pt-2"
                    ></v-select>
                  </v-col>
                </v-row>

                <!--HOTELS-->
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('lbl.hotels') }}</strong>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <AsociarHotels :id-exclude="[]" />
                  </v-col>
                </v-row>

                <!--OBSERVATIONS-->
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('lbl.observations') }}</strong>
                    <v-tooltip
                      top
                      class="text-right"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="addObservationContrateHotel()"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    v-if="observationsHotel.length > 0"
                    cols="12"
                    md="12"
                  >
                    <Observation
                      v-for="(observation, indO) in observationsHotel"
                      :key="indO"
                      :observation="observation"
                      :index="indO"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus, mdiArrowLeft, mdiSquareEditOutline, mdiDeleteOutline,
} from '@mdi/js'

import AsociarHotels from '../utils/AsociarHotels.vue'
import Observation from '../utils/Observation.vue'

export default {
  components: {
    AppCardCode,
    Observation,
    AsociarHotels,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
      },
      model: {
        source: [],
        on_request: 0,
        calcule_tarif_all_day_by_first: true,
      },
      sources: [],
      loading: false,
      tarifasAll: [],
      clasesAll: [],
      tarifasEdit: [],
      operadores: [],
      itemsOperador: [],
      searchOperador: null,
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      suplementos: [],
      currencies: [],
      rules: {
        required: value => !!value || 'Required.',
        // eslint-disable-next-line no-restricted-globals
        numeric: value => !isNaN(value) || 'Invalid value.',
        counter: value => parseInt(value, 10) <= 100 || 'Number between 1 and 100',
      },
      searchMarca: null,
      marca_id: null,
      itemsMarca: [],
      modelos: [],
      allModelos: [],
      numCategorias: 0,
      catObjects: [],
      brandObjects: [],
      categories: [],
      deletingCat: false,
      seasonObjects: [],
      numSeasons: 0,
      numMarcaModelo: 0,
      modelo_id: null,
      marcas: [],
      oficinas: [],
      editarItemSuplemento: false,
      posEditarItemSuplemento: -1,
      initTipoContrate: null,
      isDialogDeletedataTipoContrate: false,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      observationsHotel: state => state.app.observationsHotel,
      idsHotelsAsociados: state => state.app.idsHotelsAsociados,
    }),
  },
  watch: {
    searchOperador(val) {
      if (val !== null && val.length > 1) {
        this.filterOperador(val.toLowerCase())
      }
    },
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getOperadores()
    this.getProveedores()
    this.getCurrencies()
    this.getSources()

    this.getItem()
  },

  methods: {
    ...mapMutations([
      'addObservationContrateHotel',
      'updateObservationContrateHotel',
      'updateIdsHotelsAsociadosContrate',
    ]),
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.currencies = res.data.data
      })
    },
    getSources() {
      /* this.sources.push({
        name: 'Local',
        slug: 'local',
      }) */

      this.axios
        .post(
          'api_keys/verify-status',
          { slug: 'hotetecapi' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.status) {
            this.sources.push({
              name: res.data.data.api_name,
              slug: 'hotetec',
            })
          }
        })
    },
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    getItem() {
      if (sessionStorage.getItem('hotels-contrate-id') !== null) {
        const id = sessionStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data

            /* if (this.model.operador_id) {
              this.itemsOperador.push(this.model.operador)
            } */

            if (this.model.proveedor_id) {
              this.itemsProveedor.push(this.model.proveedor)
            }

            if (this.model.hotels_pactados) {
              this.updateIdsHotelsAsociadosContrate(this.model.hotels_pactados)
            } else {
              this.updateIdsHotelsAsociadosContrate([])
            }

            if (this.model.observations) {
              this.updateObservationContrateHotel(this.model.observations)
            } else {
              this.updateObservationContrateHotel([])
            }
          })
      } else {
        this.updateIdsHotelsAsociadosContrate([])
        this.updateObservationContrateHotel([])
        this.isLoading = false
        this.isLoadingSuplementos = false
      }
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadores = res.data.data
        })
    },
    getProveedores() {
      this.axios
        .get('afiliados/byproduct/hotels', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    getSuplementos() {
      this.axios
        .get('nom_suplemento_contrate/hotels?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.suplementos = res.data.data
          }
        })
    },
    save() {
      const json = {
        // operador_id: this.model.operador_id,
        proveedor_id: this.model.proveedor_id,
        identificador: this.model.identificador,
        currency_id: this.model.currency_id,
        source: this.model.source,
        observations: this.observationsHotel,
        hotels_pactados: this.idsHotelsAsociados,
      }

      if (
        /* this.model.operador_id && */
        this.model.proveedor_id
        && this.model.identificador
        && this.model.currency_id
        && this.idsHotelsAsociados.length > 0
      ) {
        let proceder = true

        if (this.observationsHotel.length > 0) {
          this.observationsHotel.forEach(element => {
            if (!element.description) {
              proceder = false
            }
          })
        }

        if (proceder) {
          this.loading = true
          if (sessionStorage.getItem('hotels-contrate-id') === null) {
            this.axios
              .post('contrate_hotels', json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                // console.log(response)
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    // this.$toast.error(this.$t('msg.noAutorice'))
                  } else if (response.data.data.status === 402) {
                    this.$toast.error(this.$t('msg.existContrate', { identificador: this.model.identificador }))
                  }
                } else {
                  sessionStorage.removeItem('hotels-contrate-id')
                  this.$router.push({ name: 'hotels-contrate-list' })
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          } else {
            this.axios
              .put(`contrate_hotels/${this.model.id}`, json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else {
                  sessionStorage.removeItem('hotels-contrate-id')
                  this.$router.push({ name: 'hotels-contrate-list' })
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    changeOperador() {
      if (this.model.operador_id) {
        const marc = this.operadores.filter(a => a.id === this.model.operador_id)[0]

        this.model.identificador = `${marc.name}`
      } else {
        this.model.identificador = null
      }
    },
    changeProveedor() {
      if (this.model.proveedor_id) {
        const mod = this.proveedores.filter(a => a.id === this.model.proveedor_id)[0]

        // this.model.identificador = `${this.model.identificador}-${mod.name_comercial}`
        const year = this.$moment(new Date()).format('Y')
        this.model.identificador = `${mod.name_comercial}-${year}-${Math.random()
          .toString(36)
          .slice(-3)
          .toUpperCase()}`
      } else {
        this.model.identificador = null
      }
    },
  },
}
</script>

<!--<style scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
::v-deep .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: center;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>-->
